<template>
	<div>
		<div class="password-input">
			<input
				class="password-input__input"
				v-bind:id="id"
				v-bind:autocomplete="autocomplete"
				v-bind:autofocus="autofocus"
				v-bind:disabled="disabled"
				v-bind:minlength="minLength"
				v-bind:required="required"
				v-bind:size="size"
				v-bind:type="type"
				v-bind:value="modelValue"
				@input="input"
				ref="input"
			/>
			<button
				class="transition password-input__btn border-t-2 border-r-2 border-b-2"
				v-bind:disabled="disabled"
				@click.stop.prevent="show = !show"
				v-bind:title="show ? $t('HIDE_PASSWORD') : $t('SHOW_PASSWORD')"
				type="button"
			>
				<i class="icon">
					<svg-icon width="24" height="24" :name="`${toggleIcon}`" /></i
				><span class="visuallyhidden" v-if="show">{{
					$t('HIDE_PASSWORD')
				}}</span
				><span class="visuallyhidden" v-else>{{ $t('SHOW_PASSWORD') }}</span>
			</button>
		</div>
		<div class="password-input-strength" v-if="strengthIndicator">
			<progress
				v-bind:value="strength.score"
				max="4"
				v-bind:class="progressColor"
				v-bind:title="$t('PASSWORD_STRENGTH')"
				style="width: 100%"
			></progress>
			<div v-if="modelValue && modelValue.length < minLength">
				{{ $t('PASSWORD_FEEDBACK_MIN_CHARACTERS', { minLength }) }}
			</div>
			<div v-else-if="modelValue">{{ passwordFeedback }}</div>
			<div v-else>{{ $t('PASSWORD_FEEDBACK') }}</div>
		</div>
	</div>
</template>
<script>
import zxcvbn from 'zxcvbn'

export default {
	name: 'form-password-input',
	props: {
		autocomplete: {
			type: String,
			default: 'current-password',
		},
		autofocus: {
			type: Boolean,
			default: false,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		id: {
			type: String,
			required: true,
		},
		minLength: {
			type: Number,
			default: 6,
		},
		required: {
			type: Boolean,
			default: false,
		},
		size: {
			type: Number,
			default: 70,
		},
		strengthIndicator: {
			type: Boolean,
			default: false,
		},
		modelValue: {
			type: String,
			required: true,
		},
	},
	data() {
		return {
			show: false,
		}
	},
	computed: {
		passwordFeedback() {
			const strength = this.strength.score
			return strength > 2
				? this.$t('PASSWORD_FEEDBACK_3')
				: strength > 1
				? this.$t('PASSWORD_FEEDBACK_2')
				: strength > 0
				? this.$t('PASSWORD_FEEDBACK_1')
				: this.$t('PASSWORD_FEEDBACK_0')
		},
		progressColor() {
			const strength = this.strength.score
			return strength > 2
				? 'green'
				: strength > 1
				? 'yellow'
				: strength > 0
				? 'orange'
				: 'red'
		},
		toggleIcon() {
			return this.show ? 'eye-open' : 'eye-closed'
		},
		type() {
			return this.show ? 'text' : 'password'
		},
		strength() {
			return zxcvbn(this.modelValue)
		},
	},
	methods: {
		input() {
			this.$emit('update:modelValue', this.$refs.input.value)
		},
	},
}
</script>
